import { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useDispatch } from 'react-redux'
import { useRefreshTokenMutation } from 'actions/users'
import { loginUser, logoutUser } from 'slices/user'
import WebPages from 'pages/web'
import Admin from 'pages/admin'
import Agent from 'pages/agence'
import User from 'pages/user'
import Welcome from 'components/cards/welcome'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'


function App() {
  const dispatch = useDispatch()
  const [refreshToken] = useRefreshTokenMutation()
  const [isReady, setIsready] = useState(false)

  async function onLoad() {
    if (localStorage.getItem('authToken')) {
      const { data, error } = await refreshToken()
      if (data) {
        dispatch(loginUser(data))
      } else if (error) {
        dispatch(logoutUser())
      }
    }
    setIsready(true)
  }

  useEffect(() => {
    onLoad()
    AOS.init()
    // eslint-disable-next-line
  }, [])

  return (
    <Router>
      {!isReady ? <Welcome />
        : <Routes>
          <Route path="*" exact element={<WebPages />} />
          <Route path="/admin/*" element={<Admin />} />
          <Route path="/agent/*" element={<Agent />} />
          <Route path="/client/*" element={<User />} />
        </Routes>
      }
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Router>
  )
}

export default App
