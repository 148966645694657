import GetClient from 'pages/web/GetClient'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'

function Footer() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Moves every 3 seconds
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <footer id='footer' className='footer'>
      <div className='footer-top'>
        <div className='container'>
          <div className='row gy-4'>
            <div className='col-lg-5 col-md-12 footer-info'>
              <Link title='apply board' to='/' className='logo d-flex justify-content-center mb-4 align-items-center'>
                <img src='/uhdss_logo.png' alt='uhdss logo' />
                <span id='hdbrand'>UHDSS</span>
                <div className='d-flex flex-row align-self-end' style={{ paddingBottom: '2px' }}>
                  <p className='ps-3 fs-6 fw-bold p-0 dark-blue'>Education agency</p>
                </div>
              </Link>
              <p className='col-md-7 fs-6 dark-blue mx-auto'>
                UHDSS education agency is Africa&apos;s best education agency for students who want to study abroad, immigration services, visa services for visitors, business visa, Tourist visa, and other Tourism Services.
              </p>
              <div className='social-links d-flex justify-content-evenly col-md-5 mx-auto mt-3'>
                <Link to='#' onClick={() => window.open('https://twitter.com/UHDSS3')} className='twitter'><i className='bi bi-twitter'></i></Link>
                <Link to='#' onClick={() => window.open('https://www.facebook.com/profile.php?id=100063764604057&mibextid=LQQJ4d')} className='facebook'><i className='bi bi-facebook'></i></Link>
                <Link to='#' onClick={() => window.open('https://www.instagram.com/uhdss_education_agency/')} className='instagram'><i className='bi bi-instagram'></i></Link>
                <Link to='#' className='linkedin'><i className='bi bi-linkedin'></i></Link>
              </div>
            </div>

            <div className='col-lg-2 col-6 footer-links'>
              <h4>Useful Links</h4>
              <ul>
                <li><Link to='/'>Home</Link></li>
                <li><Link to='/about'>About us</Link></li>
                <li><Link to='/services'>Services</Link></li>
                <li><Link to='/housing'>Accommodations</Link></li>
              </ul>
            </div>

            <div className='col-lg-2 col-6 footer-links'>
              <h4>Our Services</h4>
              <ul>
                <li><Link to='/account?reqapp=true'>Apply abroad</Link></li>
                <li><Link to='/apply/visa'>Visa Application</Link></li>
                <li><Link to='/apply/agent'>Request for Agent</Link></li>
              </ul>
            </div>

            <div className='col-lg-3 col-md-12 footer-contact text-center text-md-start'>
              <h4>Contact Us</h4>
              <p>
                Head Office <br />
                Rubangura, 2<sup>nd</sup> floor, room 203 <br />
                KN 2 St, Kigali<br />
                Kigali Rwanda <br /><br />
                <Link to='#' onClick={() => window.open('tel:+250788941631')}>
                  <span style={{ marginRight: '7px' }}><i className='bi bi-telephone'></i></span>
                  +250 788 941 631
                </Link><br />

                <Link to='#' onClick={() => window.open('mailto:info@uhdss.com')}>
                  <span style={{ marginRight: '7px' }}><i className='bi bi-envelope'></i></span>
                  info@uhdss.com
                </Link>
              </p>
            </div>
          </div>

          {/* Locations Section */}
          <div className='row mt-5'>
            <h4 className='text-center'>Our Locations</h4>
            <Slider {...settings}>
              {[
                {
                  img:'/about/Flag-Rwanda.jpg',
                  title: 'Kigali - Rwanda',
                  address: 'Rubangura, 2nd Floor, Room 203, KN 2 St, Kigali',
                  tel: '+250 788 941 631',
                  email: 'info@uhdss.com',
                },
                {
                  img:'/about/Flag-Rwanda.jpg',
                  title: 'Musanze - Rwanda',
                  address: 'Rubangura, 2nd Floor, Room 203, KN 2 St, Kigali',
                  tel: '+250 788 941 631',
                  email: 'info@uhdss.com',
                },
                {
                  img:'/about/tanzania.png',
                  title: 'Dar es Salaam - Tanzania',
                  address: 'Street Mikocheni A, Road MWAI KIBAKI, Plot number 89, Block number 12, House number 39',
                  tel: '+255 712 003 722',
                  email: 'dar@uhdss.com',
                },
                {
                  img:'/about/ethiopia.png',
                  title: 'Addis Ababa - Ethiopia',
                  address: ' Industrial Park project building,office #205,second floorof the new flower office,next to Getfam Hotel, 22 sub city',
                  tel: '+251 912 345 678',
                  email: 'addis@uhdss.com',
                },
                {
                  img:'/about/burundi.png',
                  title: 'Bujumbura - Burundi',
                  address: 'Route Rumonge(RN3), En face du station king star, 3 avenue Zone Kanyosha',
                  tel: '+257 69 35 92 20',
                  email: 'bujumbura@uhdss.com',
                },
              ].map((location, index) => (
                <div className='card location-card mr-2' key={index}>
                  <div className='card-body' style={{ height: '220px' }}>
                    <div className='d-flex align-items-center' >
                      <img className='me-3' style={{height: '50px', width: '50px', objectFit: 'contain'}} src={location.img} alt={location.title} />
                      <h5 className='card-title'>{location.title}</h5>
                    </div>
                    <p className='card-text'>
                      {location.address}<br />
                      Tel: {location.tel}<br />
                      Email: {location.email}
                    </p>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>

      <GetClient />

      <div className='container'>
        <div className='copyright'>
          &copy; Copyright <Link to=''><strong><span>UHDSS</span></strong></Link>. All Rights Reserved
        </div>
      </div>
    </footer>
  )
}

export default Footer
